<template>
  <div class="dashboard-container">
    <h2>导入日志</h2>
    <el-form ref="form" :inline="true" :model="queryForm">
      <el-form-item label="导入类型">
        <el-select
          v-model="queryForm.type"
          clearable
          filterable
          placeholder="请选择"
          style="width: 180px"
          @change="handleQuery"
        >
          <el-option
            v-for="item in optionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="handleQuery">查询</el-button>
    </el-form>
    <div style="height: calc(100vh - 300px)">
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        border
        :data="list"
        element-loading-text="拼命加载中"
        height="100%"
      >
        <el-table-column align="center" label="ID" prop="id" />
        <el-table-column align="center" label="导入类型" prop="typeName" />
        <el-table-column align="center" label="数据记录" prop="size" />
        <el-table-column
          align="center"
          label="成功数据记录"
          prop="successSize"
        />
        <el-table-column align="center" label="失败数据记录" prop="failSize" />
        <el-table-column
          align="center"
          label="原始文件上传路径"
          prop="fileUrl"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="上传失败路径"
          prop="failUrl"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="备注"
          prop="remark"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="记录创建人" prop="createBy" />
        <el-table-column
          align="center"
          label="记录创建时间"
          prop="createTime"
        />
        <el-table-column align="center" fixed="right" label="操作">
          <template #default="{ row }">
            <el-button type="text" @click="handleUpdata(row)">
              导出失败
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { excelImportLogGetPageList } from '@/api/excel';
  export default {
    name: 'ExportLog',
    data() {
      return {
        listLoading: false,
        list: [],
        height: this.$baseTableHeight(2),
        queryForm: {
          pageNum: 1,
          pageSize: 10,
          type: this.$route.params.type,
        },
        optionList: [
          {
            id: 'upload/LessThanTruckCost',
            name: '散货零担成本上传',
          },
          {
            id: 'upload/trailCarCost',
            name: '拖车成本上传',
          },
          {
            id: 'upload/UrbanDistributionCost',
            name: '散货城配成本上传',
          },
          {
            id: 'upload/TruckBaseFareRules',
            name: '整车基础计价规则',
          },
          {
            id: 'upload/TruckRouteRatios',
            name: '线路比例和加价表',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    mounted() {
      this.handleQuery()
    },
    activated() {
      this.handleQuery()
    },
    methods: {
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.handleQuery()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.handleQuery()
      },
      async handleQuery() {
        const res = await excelImportLogGetPageList(this.queryForm)
        this.list = res.data.pageList
        this.total = res.data.totalSize
      },
      handleUpdata(row) {
        window.open(row.failUrl)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bt {
    padding: 30px 0px 0px 30px;
  }
</style>
