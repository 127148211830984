
import request from '@/utils/request'
let resquest = "/api/"

// 分页查询我的导入
export function excelImportLogGetPageList(data) {
    return request({
        url: `${resquest}excel/importLog/getPageList`,
        method: 'post',
        data
    })
  }